.tableCalendar td {
  width: 12.5%;
  text-align: center;
  vertical-align: middle !important;
  font-family: sans-serif;
  /* font-size: "100px"; */
}

.tableCalendar thead th {
  text-align: center;

  color: white;
  background-color: grey;
}
