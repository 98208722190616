.onClickButton {
  width: 30%;
  padding: 5px;
  margin-top: 20px;
}
.onClickButton:hover {
  cursor: pointer;
  background-color: grey;
  transition: 0.7s linear;
}
