.popUp,
.popUpStripe {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
}
.popUp div {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.popUpStripe div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}
.popUp button {
  width: 30%;
  padding: 5px;
  margin-top: 20px;
}
.popUp button:hover {
  cursor: pointer;
  background-color: grey;
  transition: 0.7s linear;
}
