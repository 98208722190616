.square_btn {
  width: 100%;
  max-width: 150px;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  background: #668ad8; /*Button Color*/
  color: #fff;
  border-bottom: solid 4px #627295;
  border-radius: 3px;
  margin: 20px auto;
}
.square_btn:active {
  /*on Click*/
  -ms-transform: translateY(4px);
  -webkit-transform: translateY(4px);
  transform: translateY(4px); /*Move down*/
  border-bottom: none; /*disappears*/
}
.checkout-form {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
